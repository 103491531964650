import { render, staticRenderFns } from "./TableDdlDetails.vue?vue&type=template&id=020b3774"
import script from "./TableDdlDetails.vue?vue&type=script&lang=js"
export * from "./TableDdlDetails.vue?vue&type=script&lang=js"
import style0 from "./TableDdlDetails.vue?vue&type=style&index=0&id=020b3774&prod&lang=less&scope=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "TableDdlDetails.vue"
export default component.exports